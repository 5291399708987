import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  dataEtalon: [],
  selectedEtalon: null,
  metaEtalon: null | undefined,
  isLoading: true,
  searchQuery: '',

  createData: {
    device_id: '',
    kit_group_id: '',
    is_send_to_arshin: true,
    company_id: '',
    status: 'in_storage',
    name: '',
    type: '',
    register_number: '',
    fif_register_number: '',
    is_registered: true,
    manufacturer_name: '',
    release_year: '',
    manufacturer_number: '',
    meas_range: '',
    repr_range: '',
    error: '',
    kit_accuracy_class_id: '',
    deviations: '',
    software_info: '',
    component_info: '',
    receipt_date: '',
    commission_date: '',
    inventory_number: '',
    responsible_passport_name: '',
    location: '',
    interval_verifications: '',
    interval_attestations: '',
    interval_maintenances: '',

    kitVerifications: [],
    kitAttestations: [],
    kitCalibrations: [],
    kitRepairs: [],
    kitMaintenances: [],

    kitVerificationsForm: {
      place: '',
      date: '',
      interval: '',
      is_suitable: false,
      certificate_number: '',
      link: '',
    },
    kitAttestationsForm: {
      place: '',
      date: '',
      interval: '',
      is_suitable: false,
      certificate_number: '',
      link: '',
    },
    kitMaintenancesForm: {
      date: '',
      interval: '',
      by_name: '',
      is_checked: null,
    },
    kitCalibrationsForm: {
      place: '',
      date: '',
      certificate_number: '',
    },
    kitRepairsForm: {
      place: '',
      date: '',
      fault_reason: '',
      procedure_description: '',
      conclusion: '',
    },
  },
}

export const etalonReducer = createSlice({
  name: 'etalonReducer',
  initialState,
  reducers: {
    setDataEtalon: (state, action) => {
      state.dataEtalon = action.payload
    },
    setMetaEtalon: (state, action) => {
      state.metaEtalon = action.payload
    },

    changeEtalon: (state, action) => {
      state.createData[action.payload.value] = action.payload.label
    },

    changeEtalonVerifications: (state, action) => {
      state.createData.kitVerifications.unshift(action.payload)
    },
    changeEtalonAttestations: (state, action) => {
      state.createData.kitAttestations.unshift(action.payload)
    },
    changeEtalonCalibrations: (state, action) => {
      state.createData.kitCalibrations.unshift(action.payload)
    },
    changeEtalonRepairs: (state, action) => {
      state.createData.kitRepairs.unshift(action.payload)
    },
    changeEtalonMaintenances: (state, action) => {
      state.createData.kitMaintenances.unshift(action.payload)
    },

    changeEtalonVerificationsForm: (state, action) => {
      state.createData.kitVerificationsForm[action.payload.value] = action.payload.label
    },
    resetEtalonVerificationsForm: state => {
      state.createData.kitVerificationsForm = initialState.createData.kitVerificationsForm
    },
    changeEtalonAttestationsForm: (state, action) => {
      state.createData.kitAttestationsForm[action.payload.value] = action.payload.label
    },
    resetEtalonAttestationsForm: state => {
      state.createData.kitAttestationsForm = initialState.createData.kitAttestationsForm
    },
    changeEtalonMaintenancesForm: (state, action) => {
      state.createData.kitMaintenancesForm[action.payload.value] = action.payload.label
    },
    resetEtalonMaintenancesForm: state => {
      state.createData.kitMaintenancesForm = initialState.createData.kitMaintenancesForm
    },
    changeEtalonCalibrationsForm: (state, action) => {
      state.createData.kitCalibrationsForm[action.payload.value] = action.payload.label
    },
    resetEtalonCalibrationsForm: state => {
      state.createData.kitCalibrationsForm = initialState.createData.kitCalibrationsForm
    },
    changeEtalonRepairsForm: (state, action) => {
      state.createData.kitRepairsForm[action.payload.value] = action.payload.label
    },
    resetEtalonRepairsForm: state => {
      state.createData.kitRepairsForm = initialState.createData.kitRepairsForm
    },

    resetCreateData: state => {
      state.createData = initialState.createData
    },

    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload
    },

    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },
})

export const {
  setDataEtalon,
  setMetaEtalon,

  changeEtalon,
  changeEtalonVerifications,
  changeEtalonAttestations,
  changeEtalonCalibrations,
  changeEtalonMaintenances,
  changeEtalonRepairs,

  changeEtalonVerificationsForm,
  resetEtalonVerificationsForm,

  changeEtalonAttestationsForm,
  resetEtalonAttestationsForm,

  changeEtalonMaintenancesForm,
  resetEtalonMaintenancesForm,

  changeEtalonCalibrationsForm,
  resetEtalonCalibrationsForm,

  changeEtalonRepairsForm,
  resetEtalonRepairsForm,

  resetCreateData,
  setSearchQuery,
  setIsLoading,
} = etalonReducer.actions
export default etalonReducer.reducer
