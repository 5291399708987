// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import TabsContainerCheckTable from './TabsContainerCheckTable.tsx'
import TabsContainerAttestationTable from './TabsContainerAttestationTable.tsx'
import TabsContainerTSTable from './TabsContainerTSTable.tsx'
import TabsContainerCalibrationTable from './TabsContainerCalibrationTable.tsx'
import TabsContainerRepairTable from './TabsContainerRepairTable.tsx'

import CreateCheckItem from './CreateCheckItem.tsx'
import CreateAttestationItem from './CreateAttestationItem.tsx'
import CreateTSItem from './CreateTSItem.tsx'
import CreateCalibrationItem from './CreateCalibrationItem.tsx'
import CreateRepairItem from './CreateRepairItem.tsx'

import Input from '../../../ui/input/index.tsx'
import Tooltip from '../../../ui/tooltip/index.jsx'
import { RadioGroup, Radio, FormControlLabel } from '@mui/material'

import {
  setOpen as setOpenBottomContainer,
  setChild,
  setTitle,
} from '../../../store/slices/app/comps/bottomContainer'
import {
  changeEtalonVerificationsForm,
  changeEtalonAttestationsForm,
  changeEtalonMaintenancesForm,
  changeEtalon,
} from '../../../store/slices/app/views/etalon.js'

import { IModelEtalonCreate } from '../../types.ts'
import css from '../../styles.css.ts'
import dayjs from 'dayjs'

const { ContentRow, Etalons } = css

const TabsContainer: React.FC<{
  mt?: string
  mb?: string
  status?: { tab: number; title: string } | null
}> = ({ mt, status = null }): ReactElement => {
  const dispatch = useDispatch()

  const [mpiFocused, setMpiFocused] = useState<boolean>(false)
  const [maiFocused, setMaiFocused] = useState<boolean>(false)
  const [toFocused, setToFocused] = useState<boolean>(false)
  const [tabsStatus, setTabsStatus] = useState<
    | 'Срок действия поверки скоро истечет'
    | 'Срок действия аттестации скоро истчет'
    | 'Приближается срок проведении планового технического обслуживания'
    | 'Необходимо внести данные об актуальной поверке'
    | 'Истек срок действия поверки, обновите данные'
    | 'Истек срок действия аттестации, обновите данные'
    | 'Истек срок действия технического обслуживания, обновите данные'
    | 'СП не прошло поверку'
    | 'Поверка недействительна, прибор прошел ремонт / модификацию / регулировку'
    | string
  >('')
  const [statusColor, setStatusColor] = useState<'#DB1E2A' | '#E57004'>('#DB1E2A')

  const etalonCreateData: IModelEtalonCreate = useSelector(
    (state: any) => state.etalonReducer.createData,
  )

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0)
  const [containerTitle, setContainerTitle] = useState<
    | 'Сведения о поверках'
    | 'Сведения об аттестации (испытаниях)'
    | 'Сведения о проведении ТО'
    | 'Сведения о калибровках'
    | 'Сведения о проведении ремонта'
  >('Сведения о поверках')

  const handleTabIndex = (param: number): void => setActiveTabIndex(param)

  const tabButtonFilterCSSValue = ((): string => {
    if (activeTabIndex === 0) {
      if (
        etalonCreateData.interval_verifications &&
        etalonCreateData.interval_verifications.toString().length > 0
      ) {
        return 'grayscale(0)'
      } else {
        return 'grayscale(1)'
      }
    }

    if (activeTabIndex === 1) {
      if (
        etalonCreateData.interval_attestations &&
        etalonCreateData.interval_attestations.toString().length > 0
      ) {
        return 'grayscale(0)'
      } else {
        return 'grayscale(1)'
      }
    }

    if (activeTabIndex === 2) {
      if (
        etalonCreateData.interval_maintenances &&
        etalonCreateData.interval_maintenances.toString().length > 0
      ) {
        return 'grayscale(0)'
      } else {
        return 'grayscale(1)'
      }
    }

    if (activeTabIndex === 3) {
      return 'grayscale(0)'
    }

    if (activeTabIndex === 4) {
      return 'grayscale(0)'
    }

    return 'grayscale(0)'
  })()

  useEffect(() => {
    activeTabIndex === 0 && setContainerTitle('Сведения о поверках')
    activeTabIndex === 1 && setContainerTitle('Сведения об аттестации (испытаниях)')
    activeTabIndex === 2 && setContainerTitle('Сведения о проведении ТО')
    activeTabIndex === 3 && setContainerTitle('Сведения о калибровках')
    activeTabIndex === 4 && setContainerTitle('Сведения о проведении ремонта')

    activeTabIndex === 0 && dispatch(setTitle('Сведения о новой поверке'))
    activeTabIndex === 1 && dispatch(setTitle('Сведения о новой аттестации'))
    activeTabIndex === 2 && dispatch(setTitle('Сведения о проведении планового ТО'))
    activeTabIndex === 3 && dispatch(setTitle('Сведения о новой калибровке'))
    activeTabIndex === 4 && dispatch(setTitle('Сведения о последнем ремонте'))

    activeTabIndex === 0 && dispatch(setChild(<CreateCheckItem />))
    activeTabIndex === 1 && dispatch(setChild(<CreateAttestationItem />))
    activeTabIndex === 2 && dispatch(setChild(<CreateTSItem />))
    activeTabIndex === 3 && dispatch(setChild(<CreateCalibrationItem />))
    activeTabIndex === 4 && dispatch(setChild(<CreateRepairItem />))
  }, [activeTabIndex])

  useEffect(() => {
    return () => {
      dispatch(setTitle(''))
      dispatch(setChild(null))
    }
  }, [])

  useEffect(() => {
    status?.title && setTabsStatus(status.title)
  }, [status?.title, status?.tab])

  useEffect(() => {
    switch (activeTabIndex) {
      case 0:
        if (etalonCreateData.kitVerifications.length === 0 && false) {
          setTabsStatus('Необходимо внести данные об актуальной поверке')
          setStatusColor('#DB1E2A')
        } else if (
          etalonCreateData.kitVerifications.length > 0 &&
          (etalonCreateData.kitVerifications[0].is_suitable === false ||
            etalonCreateData.kitVerifications[0].is_suitable === 'false')
        ) {
          setTabsStatus('СП не прошло поверку')
          setStatusColor('#DB1E2A')
        } else if (
          etalonCreateData.kitVerifications.length > 0 &&
          (etalonCreateData.kitVerifications[0].is_suitable === true ||
            etalonCreateData.kitVerifications[0].is_suitable === 'true') &&
          dayjs(etalonCreateData.kitVerifications[0].date)
            .add(Number(etalonCreateData.kitVerifications[0].interval) || 0, 'month')
            .isAfter(dayjs()) === false
        ) {
          setTabsStatus('Истек срок действия поверки, обновите данные')
          setStatusColor('#DB1E2A')
        } else if (
          etalonCreateData.kitVerifications.length > 0 &&
          (etalonCreateData.kitVerifications[0].is_suitable === true ||
            etalonCreateData.kitVerifications[0].is_suitable === 'true') &&
          (dayjs(etalonCreateData.kitVerifications[0].date)
            .add(Number(etalonCreateData.kitVerifications[0].interval) || 0, 'month')
            .isBefore(dayjs().add(1, 'month')) ||
            dayjs(etalonCreateData.kitVerifications[0].date)
              .add(Number(etalonCreateData.kitVerifications[0].interval) || 0, 'month')
              .isSame(dayjs().add(1, 'month')))
        ) {
          setTabsStatus('Срок действия поверки скоро истечет')
          setStatusColor('#E57004')
        } else {
          setTabsStatus('')
          setStatusColor('#E57004')
        }
        break

      case 1:
        if (
          etalonCreateData.kitAttestations.length > 0 &&
          (etalonCreateData.kitAttestations[0].is_suitable === true ||
            etalonCreateData.kitAttestations[0].is_suitable === 'true') &&
          (etalonCreateData.kitAttestations[0].is_suitable === true ||
            etalonCreateData.kitAttestations[0].is_suitable === 'true') &&
          dayjs(etalonCreateData.kitAttestations[0].date)
            .add(Number(etalonCreateData.kitAttestations[0].interval) || 0, 'month')
            .isAfter(dayjs()) === false
        ) {
          setTabsStatus('Истек срок действия аттестации, обновите данные')
          setStatusColor('#DB1E2A')
        } else if (
          etalonCreateData.kitAttestations.length > 0 &&
          (etalonCreateData.kitAttestations[0].is_suitable === true ||
            etalonCreateData.kitAttestations[0].is_suitable === 'true') &&
          (etalonCreateData.kitAttestations[0].is_suitable === true ||
            etalonCreateData.kitAttestations[0].is_suitable === 'true') &&
          (dayjs(etalonCreateData.kitAttestations[0].date)
            .add(Number(etalonCreateData.kitAttestations[0].interval) || 0, 'month')
            .isBefore(dayjs().add(1, 'month')) ||
            dayjs(etalonCreateData.kitAttestations[0].date)
              .add(Number(etalonCreateData.kitAttestations[0].interval) || 0, 'month')
              .isSame(dayjs().add(1, 'month')))
        ) {
          setTabsStatus('Срок действия аттестации скоро истчет')
          setStatusColor('#E57004')
        } else {
          setTabsStatus('')
          setStatusColor('#E57004')
        }
        break

      case 2:
        if (
          etalonCreateData.kitMaintenances.length > 0 &&
          dayjs(etalonCreateData.kitMaintenances[0].date)
            .add(Number(etalonCreateData.kitMaintenances[0].interval) || 0, 'month')
            .isAfter(dayjs()) === false
        ) {
          setTabsStatus('Истек срок действия технического обслуживания, обновите данные')
          setStatusColor('#DB1E2A')
        } else if (
          etalonCreateData.kitMaintenances.length > 0 &&
          (dayjs(etalonCreateData.kitMaintenances[0].date)
            .add(Number(etalonCreateData.kitMaintenances[0].interval) || 0, 'month')
            .isBefore(dayjs().add(1, 'month')) ||
            dayjs(etalonCreateData.kitMaintenances[0].date)
              .add(Number(etalonCreateData.kitMaintenances[0].interval) || 0, 'month')
              .isSame(dayjs().add(1, 'month')))
        ) {
          setTabsStatus('Приближается срок проведении планового технического обслуживания')
          setStatusColor('#E57004')
        } else {
          setTabsStatus('')
          setStatusColor('#E57004')
        }
        break

      default:
        break
    }
  }, [
    etalonCreateData.status,
    etalonCreateData.kitVerifications,
    etalonCreateData.kitAttestations,
    etalonCreateData.kitMaintenances,
    activeTabIndex,
  ])

  return (
    <React.Fragment>
      <ContentRow>
        <Etalons.TabsContainer style={mt ? { marginTop: mt } : {}}>
          <Etalons.TabsContainerButton
            active={activeTabIndex === 0}
            onClick={() => handleTabIndex(0)}
          >
            Поверка
          </Etalons.TabsContainerButton>
          <Etalons.TabsContainerButton
            active={activeTabIndex === 1}
            onClick={() => handleTabIndex(1)}
          >
            Аттестация
          </Etalons.TabsContainerButton>
          <Etalons.TabsContainerButton
            active={activeTabIndex === 2}
            onClick={() => handleTabIndex(2)}
          >
            Техобслуживание
          </Etalons.TabsContainerButton>
          <Etalons.TabsContainerButton
            active={activeTabIndex === 3}
            onClick={() => handleTabIndex(3)}
          >
            Калибровка
          </Etalons.TabsContainerButton>
          <Etalons.TabsContainerButton
            active={activeTabIndex === 4}
            onClick={() => handleTabIndex(4)}
          >
            Ремонт
          </Etalons.TabsContainerButton>
        </Etalons.TabsContainer>
      </ContentRow>
      <ContentRow>
        <Etalons.TabsContainerWorkSpace>
          <ContentRow
            style={{
              paddingTop: '20px',
              paddingLeft: '20px',
              paddingRight: '20px',
              alignItems: 'flex-start',
            }}
            axios='space-between'
          >
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                gap: '20px',
                filter: tabButtonFilterCSSValue,
              }}
            >
              <h2>{containerTitle}</h2>
              <Tooltip
                title={
                  activeTabIndex === 0 && etalonCreateData.interval_verifications === ''
                    ? 'Для добавления записи внесите данные о МПИ'
                    : activeTabIndex === 1 && etalonCreateData.interval_attestations === ''
                      ? 'Для добавления записи внесите данные о МАИ'
                      : activeTabIndex === 2 && etalonCreateData.interval_maintenances === ''
                        ? 'Для добавления записи внесите данные о периодичности ТО'
                        : '000'
                }
                disableHoverListener={
                  !(
                    (activeTabIndex === 0 && etalonCreateData.interval_verifications === '') ||
                    (activeTabIndex === 1 && etalonCreateData.interval_attestations === '') ||
                    (activeTabIndex === 2 && etalonCreateData.interval_maintenances === '')
                  )
                }
              >
                <Etalons.TabsContainerWorkSpaceAddButton
                  onClick={() => {
                    if (activeTabIndex === 0) {
                      if (
                        etalonCreateData.interval_verifications &&
                        etalonCreateData.interval_verifications.toString().length > 0
                      ) {
                        dispatch(setOpenBottomContainer(true))
                      } else {
                        setMpiFocused(true)
                        setTimeout(() => setMpiFocused(false), 0)
                      }
                    }
                    if (activeTabIndex === 1) {
                      if (
                        etalonCreateData.interval_attestations &&
                        etalonCreateData.interval_attestations.toString().length > 0
                      ) {
                        dispatch(setOpenBottomContainer(true))
                      } else {
                        setMaiFocused(true)
                        setTimeout(() => setMaiFocused(false), 0)
                      }
                    }
                    if (activeTabIndex === 2) {
                      if (
                        etalonCreateData.interval_maintenances &&
                        etalonCreateData.interval_maintenances.toString().length > 0
                      ) {
                        dispatch(setOpenBottomContainer(true))
                      } else {
                        setToFocused(true)
                        setTimeout(() => setToFocused(false), 0)
                      }
                    }
                    if (activeTabIndex === 3) {
                      dispatch(setOpenBottomContainer(true))
                    }
                    if (activeTabIndex === 4) {
                      dispatch(setOpenBottomContainer(true))
                    }
                  }}
                >
                  <svg
                    width='25'
                    height='25'
                    viewBox='0 0 25 25'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M16.8278 13.5266C18.4537 12.2448 19.4975 10.2575 19.4975 8.02637H21.4975C21.4975 10.9984 20.057 13.6339 17.836 15.2729L20.3636 19.6507C20.9159 20.6073 20.5881 21.8305 19.6315 22.3828L16.1048 16.2743C15.0005 16.758 13.7803 17.0264 12.4975 17.0264C11.2147 17.0264 9.99468 16.758 8.89034 16.2743L5.36362 22.3828C4.40703 21.8305 4.07928 20.6073 4.63157 19.6507L9.69507 10.8805C8.95601 10.1547 8.49759 9.14406 8.49759 8.02637C8.49759 6.16253 9.77237 4.59643 11.4975 4.15239V2.02637H13.4975V4.15239C15.2228 4.59643 16.4975 6.16253 16.4975 8.02637C16.4975 9.14406 16.0391 10.1547 15.3001 10.8805L16.8278 13.5266ZM15.0966 14.528L13.5685 11.8814C13.2277 11.9759 12.8685 12.0264 12.4975 12.0264C12.1266 12.0264 11.7674 11.9759 11.4266 11.8814L9.89858 14.528C10.7021 14.8495 11.5792 15.0264 12.4975 15.0264C13.4159 15.0264 14.293 14.8495 15.0966 14.528ZM12.4975 9.02637C13.0498 9.02637 13.4975 8.57866 13.4975 8.02637C13.4975 7.47409 13.0498 7.02637 12.4975 7.02637C11.9453 7.02637 11.4975 7.47409 11.4975 8.02637C11.4975 8.57866 11.9453 9.02637 12.4975 9.02637Z'
                      fill='#0084E2'
                    />
                  </svg>
                  <span
                    style={{
                      color: '#0084E2',
                      fontWeight: 700,
                    }}
                  >
                    Внести новую запись
                  </span>
                </Etalons.TabsContainerWorkSpaceAddButton>
              </Tooltip>
            </div>
            <div style={{ display: 'flex', flexFlow: 'row', width: '30%' }}>
              {activeTabIndex === 0 && (
                <Input
                  className={'input'}
                  focused={mpiFocused}
                  type={'text'}
                  placeholder={'Пример ввода - 12'}
                  label={'МПИ в месяцах'}
                  value={etalonCreateData.interval_verifications || ''}
                  actions={{
                    change: (param: string) => {
                      dispatch(changeEtalonVerificationsForm({ value: 'interval', label: param }))
                      dispatch(
                        changeEtalon({
                          value: 'interval_verifications',
                          label: param,
                        }),
                      )
                    },
                  }}
                  pattern={undefined}
                  length={undefined}
                  style={undefined}
                  multiline={undefined}
                  minRows={undefined}
                  maxRows={undefined}
                  sx={undefined}
                />
              )}
              {activeTabIndex === 1 && (
                <Input
                  className={'input'}
                  focused={maiFocused}
                  notRequired={true}
                  type={'text'}
                  placeholder={'Пример ввода - 12'}
                  label={'МАИ в месяцах'}
                  value={etalonCreateData.interval_attestations || ''}
                  actions={{
                    change: (param: string) => {
                      dispatch(changeEtalonAttestationsForm({ value: 'interval', label: param }))
                      dispatch(
                        changeEtalon({
                          value: 'interval_attestations',
                          label: param,
                        }),
                      )
                    },
                  }}
                  pattern={undefined}
                  length={undefined}
                  style={undefined}
                  multiline={undefined}
                  minRows={undefined}
                  maxRows={undefined}
                  sx={undefined}
                />
              )}
              {activeTabIndex === 2 && (
                <Input
                  className={'input'}
                  focused={toFocused}
                  notRequired={true}
                  type={'text'}
                  placeholder={'Пример ввода - 12'}
                  label={'Периодичность ТО в месяцах'}
                  value={etalonCreateData.interval_maintenances || ''}
                  actions={{
                    change: (param: string) => {
                      dispatch(changeEtalonMaintenancesForm({ value: 'interval', label: param }))
                      dispatch(
                        changeEtalon({
                          value: 'interval_maintenances',
                          label: param,
                        }),
                      )
                    },
                  }}
                  pattern={undefined}
                  length={undefined}
                  style={undefined}
                  multiline={undefined}
                  minRows={undefined}
                  maxRows={undefined}
                  sx={undefined}
                />
              )}
            </div>
          </ContentRow>
          {activeTabIndex < 3 && (
            <ContentRow
              style={{
                paddingTop: '20px',
                paddingLeft: '20px',
                paddingRight: '20px',
                alignItems: 'flex-start',
              }}
              axios='space-between'
              mb={4}
            >
              <span
                style={{
                  fontSize: '17.7px',
                  fontWeight: 600,
                  color: statusColor,
                  transition: 'all 500ms',
                }}
              >
                {tabsStatus}
              </span>
            </ContentRow>
          )}

          {activeTabIndex === 2 && false && (
            <ContentRow
              style={{
                paddingTop: '10px',
                paddingLeft: '20px',
                paddingRight: '20px',
                alignItems: 'flex-start',
              }}
              mb={8}
              gap={14}
            >
              <div style={{ width: '40%' }}>
                <span>Ответственный за ТО * временно заблокировано </span>
                <RadioGroup
                  aria-labelledby='demo-controlled-radio-buttons-group'
                  name='controlled-radio-buttons-group'
                  value={''}
                  onChange={() => {}}
                  style={{
                    flexDirection: 'row',
                    fontFamily: "'Wix Madefor Display', sans-serif",
                    color: '#123532',
                    fontSize: '16px',
                    fontWeight: '400',
                    marginTop: '0px',
                    paddingLeft: '5px',
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={
                      <Radio
                        disabled={true}
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: '22px !important',
                          },
                        }}
                      />
                    }
                    label='Сотрудник компании'
                    sx={{
                      '& .MuiTypography-root': {
                        fontFamily: "'Wix Madefor Display', sans-serif !important",
                        fontSize: '15px !important',
                        fontWeight: 400,
                        color: '#132532',
                      },
                    }}
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      <Radio
                        disabled={true}
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: '22px !important',
                          },
                        }}
                      />
                    }
                    label='Внешний специалист'
                    sx={{
                      '& .MuiTypography-root': {
                        fontFamily: "'Wix Madefor Display', sans-serif !important",
                        fontSize: '15px !important',
                        fontWeight: 400,
                        color: '#132532',
                      },
                    }}
                  />
                </RadioGroup>
              </div>
              <div style={{ display: 'flex', flexFlow: 'row', width: '60%' }}>
                <Input
                  className={'input'}
                  isDisabled={true}
                  notRequired={true}
                  type={'text'}
                  placeholder={'Выберите ответственного за проведение ТО'}
                  label={'Ответственный за проведение ТО'}
                  value={''}
                  actions={{
                    change: () => {},
                  }}
                  pattern={undefined}
                  length={undefined}
                  style={undefined}
                  multiline={undefined}
                  minRows={undefined}
                  maxRows={undefined}
                  sx={undefined}
                />
              </div>
            </ContentRow>
          )}

          {activeTabIndex === 0 && <TabsContainerCheckTable />}
          {activeTabIndex === 1 && <TabsContainerAttestationTable />}
          {activeTabIndex === 2 && <TabsContainerTSTable />}
          {activeTabIndex === 3 && <TabsContainerCalibrationTable />}
          {activeTabIndex === 4 && <TabsContainerRepairTable />}
        </Etalons.TabsContainerWorkSpace>
      </ContentRow>
    </React.Fragment>
  )
}

export default TabsContainer
