// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Measurement from '../../LocalElements/Filter/FilterMeasurement'
import Feedback from '../../LocalElements/Feedback/Feedback'
import R from '../../../../services/app/request.service'
import { changePersonal, resetPersonal } from '../../../../store/slices/app/views/journals'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import { refreshPersonalMetrolog } from '../../../../store/slices/app/controlers/updater'
import Input from '../../../../ui/input/index.tsx'
import Button from '../../../../ui/button/index'
import RadioButton from '../../../../ui/radio-button/index'
import { Typography } from '@mui/material'
import { Wrapper, Frame, Content, ContentFrame, LabelFrame } from '../style'

const Add = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userRole = useSelector(state => state.headerReducer.role)
  const show = useSelector(state => state.feedbackReducer.show)
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const [errors, setErrors] = useState({})
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const masterArshine = useSelector(state => state.journalReducer.masterArshine)

  const measurement = useSelector(state => state.journalReducer.personal.measurement)
  const numSi = useSelector(state => state.journalReducer.personal.numSi)
  const nameSi = useSelector(state => state.journalReducer.personal.nameSi)
  const typeSi = useSelector(state => state.journalReducer.personal.typeSi)
  const numFactory = useSelector(state => state.journalReducer.personal.numFactory)
  const verificationDate = useSelector(state => state.journalReducer.personal.verificationDate)
  const numNotification = useSelector(state => state.journalReducer.personal.numNotification)
  const numProtocol = useSelector(state => state.journalReducer.personal.numProtocol)

  const changeMeasurement = param => {
    dispatch(changePersonal({ value: 'measurement', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, measurement_id: '' }))
  }

  const changeNumSi = param => {
    const validInput = param.replace(/[^0-9\- ]/g, '')
    dispatch(changePersonal({ value: 'numSi', label: validInput }))
    setErrors(prevErrors => ({ ...prevErrors, fif_number: '' }))
  }

  const changeNameSi = param => {
    dispatch(changePersonal({ value: 'nameSi', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, customer_arshin_name: '' }))
  }

  const changeIsNameSi = event => {
    dispatch(changePersonal({ value: 'nameSi', label: event.target.value }))
  }

  const changeTypeSi = param => {
    dispatch(changePersonal({ value: 'typeSi', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, name_type_si: '' }))
  }

  const changeNumFactory = param => {
    dispatch(changePersonal({ value: 'numFactory', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, meter_factory_number: '' }))
  }

  const handleNumNotification = param => {
    dispatch(changePersonal({ value: 'numNotification', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, certificate: '' }))
  }

  const handleNumProtocol = param => {
    dispatch(changePersonal({ value: 'numProtocol', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, protocol_number: '' }))
  }

  const handleVerificationDateChange = date => {
    if (date) {
      dispatch(
        changePersonal({
          value: 'verificationDate',
          label: date.format('YYYY-MM-DD'),
        })
      )
    } else {
      dispatch(changePersonal({ value: 'verificationDate', label: '' }))
    }
  }

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = {
      measurement_id: measurement,
      fif_number: numSi,
      customer_arshin_name: nameSi,
      name_type_si: typeSi,
      meter_factory_number: numFactory,
      verification_date: verificationDate,
      certificate: numNotification,
      protocol_number: numProtocol,
    }

    const { status, data: responseData } = await R.addPersonalMetrolog(
      companyId,
      masterArshine,
      data
    )

    if (status === 201) {
      dispatch(setMessage('Запись успешно добавлена'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshPersonalMetrolog())
      dispatch(resetPersonal())
      navigate('/metriva/journals')
    } else if (status === 422) {
      setErrors(responseData.errors || {})
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Запись не добавлена - ошибка валидации'))
      setIsDisabledBtn(false)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage(responseData.message))
      setIsDisabledBtn(false)
    }
  }

  const navigateToProtocols = () => {
    navigate('/metriva/journals')
  }

  return (
    <main className='main'>
      {show ? (
        <Feedback />
      ) : (
        <Wrapper>
          <Frame onClick={navigateToProtocols}>
            <i
              className='fas fa-arrow-left icon_back'
              style={{ width: '30px', height: '30px', marginRight: '6px' }}
            />
            <Typography variant='subtitle2'>Все записи документа</Typography>
          </Frame>

          <Typography variant='h2'>Персональный журнал инженера по метрологии</Typography>

          <Content>
            <Typography variant='h2'>Информация о журнале</Typography>
            <ContentFrame>
              <LabelFrame sx={{ width: '32.8%' }}>
                <Measurement
                  measurementId={measurement}
                  setMeasurementId={changeMeasurement}
                  label={{
                    text: (
                      <div style={{ display: 'flex', alignItems: 'center' }}>Область измерения</div>
                    ),
                    topBg: 'white',
                    bottomBg: 'white',
                  }}
                />
                {errors.measurement_id && <div className='error'>{errors.measurement_id[0]}</div>}
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'Наименование, тип СИ'}
                  placeholder={'Введите наименование и тип СИ'}
                  type={'text'}
                  value={typeSi}
                  error={!!errors.name_type_si}
                  actions={{
                    change: changeTypeSi,
                  }}
                />
                {errors.name_type_si && <div className='error'>{errors.name_type_si[0]}</div>}
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'№ СИ в госреетсре'}
                  placeholder={'Пример ввода - 349392-93'}
                  type={'text'}
                  value={numSi}
                  error={!!errors.fif_number}
                  actions={{
                    change: changeNumSi,
                  }}
                />
                {errors.fif_number && <div className='error'>{errors.fif_number[0]}</div>}
              </LabelFrame>
            </ContentFrame>

            <ContentFrame>
              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'Заводской номер'}
                  placeholder={'Пример ввода - 3829JH39'}
                  type={'text'}
                  value={numFactory}
                  error={!!errors.meter_factory_number}
                  actions={{
                    change: changeNumFactory,
                  }}
                />
                {errors.meter_factory_number && (
                  <div className='error'>{errors.meter_factory_number[0]}</div>
                )}
              </LabelFrame>

              <div style={{ display: 'flex', flexFlow: 'column', width: 'calc(65.6% + 16px)' }}>
                <Input
                  label={'Наименование владельца СИ'}
                  placeholder={'Выберите тип лица или введите наименование владельца СИ'}
                  type={'text'}
                  value={nameSi}
                  error={!!errors.customer_arshin_name}
                  actions={{
                    change: changeNameSi,
                  }}
                />
                {errors.customer_arshin_name && (
                  <div className='error'>{errors.customer_arshin_name[0]}</div>
                )}
              </div>
            </ContentFrame>
            <ContentFrame>
              <LabelFrame sx={{ width: '32.8%' }}></LabelFrame>

              <div
                style={{
                  display: 'flex',
                  flexFlow: 'column',
                  width: 'calc(65.6% + 16px)',
                  marginTop: '-10px',
                }}
              >
                <RadioButton
                  value={nameSi}
                  onChange={changeIsNameSi}
                  trueLabel={'Физическое лицо'}
                  falseLabel={'Юридическое лицо'}
                  trueValue={'Физическое лицо'}
                  falseValue={'Юридическое лицо'}
                />
                {errors.customer_arshin_name && (
                  <div className='error'>{errors.customer_arshin_name[0]}</div>
                )}
              </div>
            </ContentFrame>

            <ContentFrame>
              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'№ свидетельства или извещения'}
                  placeholder={'Пример ввода - АИК/24-08-2024/382808433'}
                  type={'text'}
                  value={numNotification}
                  error={!!errors.certificate}
                  actions={{
                    change: handleNumNotification,
                  }}
                />
                {errors.certificate && <div className='error'>{errors.certificate[0]}</div>}
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'Номер протокола поверки'}
                  placeholder={'Введите номер протокола'}
                  type={'text'}
                  value={numProtocol}
                  error={!!errors.protocol_number}
                  actions={{
                    change: handleNumProtocol,
                  }}
                />
                {errors.protocol_number && <div className='error'>{errors.protocol_number[0]}</div>}
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <DatePicker
                  selectedDate={verificationDate}
                  dateChange={handleVerificationDateChange}
                  label='Дата поверки'
                  isRequired={true}
                />
              </LabelFrame>
            </ContentFrame>
          </Content>

          <Button
            onClick={handleSave}
            label='Добавить запись'
            disabled={isDisabledBtn}
            color='primary'
            fullWidth
          />
        </Wrapper>
      )}
    </main>
  )
}

export default Add
