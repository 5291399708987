// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Measurement from '../../LocalElements/Filter/FilterMeasurement'
import Feedback from '../../LocalElements/Feedback/Feedback'
import R from '../../../../services/app/request.service'
import { changeReceived, resetReceived } from '../../../../store/slices/app/views/journals'
import { refreshReceiveds } from '../../../../store/slices/app/controlers/updater'
import dayjs from 'dayjs'
import CheckCircleIcon from '../../../../img/icon/CheckCircleIcon'
import VisibilityToggleIcon from '../../../../img/icon/VisibilityToggleIcon'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'
import Input from '../../../../ui/input/index.tsx'
import Button from '../../../../ui/button/index'
import RadioButton from '../../../../ui/radio-button/index'
import { Typography } from '@mui/material'
import { Wrapper, Frame, Content, ContentFrame, LabelFrame } from '../style'

const Add = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const [errors, setErrors] = useState({})
  const userRole = useSelector(state => state.headerReducer.role)
  const show = useSelector(state => state.feedbackReducer.show)

  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const measurementId = useSelector(state => state.journalReducer.received.measurementId)
  const nameTypeSi = useSelector(state => state.journalReducer.received.nameTypeSi)
  const fifNumber = useSelector(state => state.journalReducer.received.fifNumber)
  const factoryNumber = useSelector(state => state.journalReducer.received.meterFactoryNumber)
  const arshinName = useSelector(state => state.journalReducer.received.customerArshinName)
  const verDate = useSelector(state => state.journalReducer.received.verificationDate)
  const protocolNumber = useSelector(state => state.journalReducer.received.protocolNumber)
  const arshinMasterName = useSelector(state => state.journalReducer.received.arshinMasterName)
  const receivedDate = useSelector(state => state.journalReducer.received.receivedDate)
  const receivedByName = useSelector(state => state.journalReducer.received.receivedByName)
  const issuedName = useSelector(state => state.journalReducer.received.issuedByName)
  const issuedDate = useSelector(state => state.journalReducer.received.issuedDate)
  const totalAccepted = useSelector(state => state.journalReducer.received.totalAccepted)
  const arshinId = useSelector(state => state.journalReducer.received.arshinId)
  const typeMethod = useSelector(state => state.journalReducer.received.typeMethod)

  const changeMeasurement = param => {
    dispatch(changeReceived({ value: 'measurementId', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, measurement_id: '' }))
  }
  const changeNameTypeSi = param => {
    dispatch(changeReceived({ value: 'nameTypeSi', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, name_type_si: '' }))
  }
  const changeFifNumber = param => {
    dispatch(changeReceived({ value: 'fifNumber', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, fif_number: '' }))
  }
  const changeFactoryNumber = param => {
    dispatch(changeReceived({ value: 'meterFactoryNumber', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, meter_factory_number: '' }))
  }
  const changeArshinName = param => {
    dispatch(changeReceived({ value: 'customerArshinName', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, protocol_number: '' }))
  }
  const changeIsArshinName = event => {
    dispatch(changeReceived({ value: 'customerArshinName', label: event.target.value }))
  }
  const changeProtocolNumber = param => {
    dispatch(changeReceived({ value: 'protocolNumber', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, protocol_number: '' }))
  }
  const changeMasterName = param => {
    dispatch(changeReceived({ value: 'arshinMasterName', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, arshin_master_name: '' }))
  }
  const changeReceivedByName = param => {
    dispatch(changeReceived({ value: 'receivedByName', label: param }))
  }
  const changeIssuedNamed = param => {
    dispatch(changeReceived({ value: 'issuedByName', label: param }))
  }
  const changeArshinId = param => {
    dispatch(changeReceived({ value: 'arshinId', label: param }))
    setErrors(prevErrors => ({ ...prevErrors, certificate: '' }))
  }
  const changeTypeMethod = event => {
    dispatch(changeReceived({ value: 'typeMethod', label: event.target.value }))
    dispatch(changeReceived({ value: 'issuedByName', label: '' }))
    dispatch(changeReceived({ value: 'receivedByName', label: '' }))
    dispatch(
      changeReceived({
        value: 'receivedDate',
        label: '',
      }),
    )
    dispatch(
      changeReceived({
        value: 'issuedDate',
        label: '',
      }),
    )
    setErrors(prevErrors => ({ ...prevErrors, verification_method: '' }))
  }
  const changeTotalAccepted = event => {
    const value = event.target.value === 'true'
    dispatch(changeReceived({ value: 'totalAccepted', label: value }))
  }

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = {
      measurement_id: measurementId,
      customer_arshin_name: arshinName,
      work_content: 'no-content',
      fif_number: fifNumber,
      name_type_si: nameTypeSi,
      meter_factory_number: factoryNumber,
      verification_date: verDate,
      protocol_number: protocolNumber,
      arshin_master_name: arshinMasterName,
      total_accepted: totalAccepted,
      certificate: arshinId,
      verification_method: typeMethod,
    }

    if (typeMethod === 'laboratory') {
      data.issued_by_name = issuedName
      data.issued_date = issuedDate
      data.received_by_name = receivedByName
      data.received_date = receivedDate
    }

    const { status, data: responseData } = await R.addReceivedJournalRow(companyId, data)

    if (status === 201) {
      dispatch(setMessage('Запись успешно добавлена'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshReceiveds())
      dispatch(resetReceived())
      navigate('/metriva/journals')
    } else if (status === 422) {
      setErrors(responseData.errors || {})
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Запись не добавлена - ошибка валидации'))
      setIsDisabledBtn(false)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage(responseData.message))
      setIsDisabledBtn(false)
    }
  }

  const navigateToProtocols = () => {
    navigate('/metriva/journals')
  }

  const measurementLabelIcon = measurementId ? (
    <CheckCircleIcon
      sx={{
        color: '#63E6BE',
        marginLeft: '8px',
        marginTop: '0',
      }}
    />
  ) : (
    <VisibilityToggleIcon
      sx={{
        color: '#FFD43B',
        marginLeft: '8px',
        marginTop: '0',
      }}
    />
  )

  return (
    <main className='main'>
      {show ? (
        <Feedback />
      ) : (
        <Wrapper>
          <Frame onClick={navigateToProtocols}>
            <i
              className='fas fa-arrow-left icon_back'
              style={{ width: '30px', height: '30px', marginRight: '6px' }}
            />
            <Typography variant='subtitle2'>Все записи документа</Typography>
          </Frame>

          <Typography variant='h2'>
            Журнал учета поступивших на поверку СИ и проведения поверочных работ
          </Typography>

          <Content>
            <Typography variant='h2'>Информация о журнале</Typography>
            <ContentFrame>
              <LabelFrame sx={{ width: '32.8%' }}>
                <Measurement
                  measurementId={measurementId}
                  setMeasurementId={changeMeasurement}
                  label={{
                    text: 'Область измерения',
                    topBg: 'white',
                    bottomBg: 'white',
                  }}
                />
                {errors.measurement_id && <div className='error'>{errors.measurement_id[0]}</div>}
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'Наименование и тип СИ'}
                  placeholder={'Введите наименование и тип СИ'}
                  type={'text'}
                  value={nameTypeSi}
                  error={!!errors.name_type_si}
                  actions={{
                    change: changeNameTypeSi,
                  }}
                />
                {errors.name_type_si && <div className='error'>{errors.name_type_si[0]}</div>}
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'Номер СИ в госреестре'}
                  placeholder={'Пример ввода - 349392-93'}
                  type={'text'}
                  value={fifNumber}
                  error={!!errors.fif_number}
                  actions={{
                    change: changeFifNumber,
                  }}
                />
                {errors.fif_number && <div className='error'>{errors.fif_number[0]}</div>}
              </LabelFrame>
            </ContentFrame>

            <ContentFrame>
              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'Заводской номер СИ'}
                  placeholder={'Пример ввода - 3829JH39'}
                  type={'text'}
                  error={!!errors.meter_factory_number}
                  value={factoryNumber}
                  actions={{
                    change: changeFactoryNumber,
                  }}
                />
                {errors.meter_factory_number && (
                  <div className='error'>{errors.meter_factory_number[0]}</div>
                )}
              </LabelFrame>

              <div style={{ display: 'flex', flexFlow: 'column', width: 'calc(65.6% + 16px)' }}>
                <Input
                  label={'Владелец СИ в системе Аршин'}
                  placeholder={'Выберите тип лица или введите наименование владельца СИ'}
                  type={'text'}
                  error={!!errors.customer_arshin_name}
                  value={arshinName}
                  actions={{
                    change: changeArshinName,
                  }}
                />
                {errors.customer_arshin_name && (
                  <div className='error'>{errors.customer_arshin_name[0]}</div>
                )}
              </div>
            </ContentFrame>
            <ContentFrame>
              <LabelFrame sx={{ width: '32.8%' }}></LabelFrame>

              <div
                style={{
                  display: 'flex',
                  flexFlow: 'column',
                  width: 'calc(65.6% + 16px)',
                  marginTop: '-10px',
                }}
              >
                <RadioButton
                  value={arshinName}
                  onChange={changeIsArshinName}
                  trueLabel={'Физическое лицо'}
                  falseLabel={'Юридическое лицо'}
                  trueValue={'Физическое лицо'}
                  falseValue={'Юридическое лицо'}
                />
              </div>
            </ContentFrame>

            <ContentFrame>
              <LabelFrame sx={{ width: '32.8%' }}>
                <DatePicker
                  selectedDate={verDate}
                  label='Дата поверки'
                  dateChange={date =>
                    date
                      ? dispatch(
                          changeReceived({
                            value: 'verificationDate',
                            label: date.format('YYYY-MM-DD'),
                          }),
                        )
                      : dispatch(
                          changeReceived({
                            value: 'verificationDate',
                            label: null,
                          }),
                        )
                  }
                />
              </LabelFrame>
              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'Номер протокола поверки'}
                  type={'text'}
                  placeholder={'Введите номер протокола'}
                  value={protocolNumber}
                  error={!!errors.protocol_number}
                  actions={{
                    change: changeProtocolNumber,
                  }}
                />
                {errors.protocol_number && <div className='error'>{errors.protocol_number[0]}</div>}
              </LabelFrame>
              <LabelFrame sx={{ width: '32.8%' }}>
                <Input
                  label={'ФИО проводившего поверку'}
                  type={'text'}
                  placeholder={'Введите ФИО проводившего поверку'}
                  value={arshinMasterName}
                  error={!!errors.arshin_master_name}
                  actions={{
                    change: changeMasterName,
                  }}
                />
                {errors.arshin_master_name && (
                  <div className='error'>{errors.arshin_master_name[0]}</div>
                )}
              </LabelFrame>
            </ContentFrame>

            <ContentFrame>
              <LabelFrame sx={{ width: '32.8%' }}>
                <Typography variant='subtitle2'>Способ проведения поверки</Typography>
                <RadioButton
                  value={typeMethod}
                  onChange={changeTypeMethod}
                  trueLabel={'На выезде'}
                  falseLabel={'В лаборатории'}
                  trueValue={'field'}
                  falseValue={'laboratory'}
                />
                {errors.verification_method && (
                  <div className='error'>{errors.verification_method[0]}</div>
                )}
              </LabelFrame>

              {typeMethod === 'laboratory' && (
                <LabelFrame sx={{ width: 'calc(65.6% + 16px)' }}>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <div
                      style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '9px' }}
                    >
                      <Typography
                        variant='subtitle2'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        Дата поступления СИ{' '}
                        {!!receivedDate ? (
                          <CheckCircleIcon
                            sx={{
                              color: '#63E6BE',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        ) : (
                          <VisibilityToggleIcon
                            sx={{
                              color: '#FFD43B',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        )}
                      </Typography>
                      <DatePicker
                        selectedDate={receivedDate}
                        dateChange={date =>
                          dispatch(
                            changeReceived({
                              value: 'receivedDate',
                              label: date.format('YYYY-MM-DD'),
                            }),
                          )
                        }
                        maxDate={verDate ? dayjs(verDate) : null}
                      />
                    </div>

                    <div
                      style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '9px' }}
                    >
                      <Typography
                        variant='subtitle2'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        ФИО принявшего СИ{' '}
                        {!!receivedByName ? (
                          <CheckCircleIcon
                            sx={{
                              color: '#63E6BE',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        ) : (
                          <VisibilityToggleIcon
                            sx={{
                              color: '#FFD43B',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        )}
                      </Typography>
                      <Input
                        placeholder={'Введите ФИО принявшего СИ'}
                        notRequired={true}
                        type={'text'}
                        value={receivedByName}
                        actions={{
                          change: changeReceivedByName,
                        }}
                      />
                    </div>
                  </div>

                  <div style={{ display: 'flex', gap: '10px' }}>
                    <div
                      style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '9px' }}
                    >
                      <Typography
                        variant='subtitle2'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        Дата выдачи СИ{' '}
                        {!!issuedDate ? (
                          <CheckCircleIcon
                            sx={{
                              color: '#63E6BE',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        ) : (
                          <VisibilityToggleIcon
                            sx={{
                              color: '#FFD43B',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        )}
                      </Typography>
                      <DatePicker
                        selectedDate={issuedDate}
                        dateChange={date =>
                          dispatch(
                            changeReceived({
                              value: 'issuedDate',
                              label: date.format('YYYY-MM-DD'),
                            }),
                          )
                        }
                        minDate={verDate ? dayjs(verDate) : null}
                      />
                    </div>

                    <div
                      style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '9px' }}
                    >
                      <Typography
                        variant='subtitle2'
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        ФИО выдавшего СИ{' '}
                        {!!issuedName ? (
                          <CheckCircleIcon
                            sx={{
                              color: '#63E6BE',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        ) : (
                          <VisibilityToggleIcon
                            sx={{
                              color: '#FFD43B',
                              marginLeft: '8px',
                              marginTop: '-0px',
                            }}
                          />
                        )}
                      </Typography>
                      <Input
                        type={'text'}
                        placeholder={'Введите ФИО выдавшего СИ'}
                        notRequired={true}
                        value={issuedName}
                        actions={{
                          change: changeIssuedNamed,
                        }}
                      />
                    </div>
                  </div>
                </LabelFrame>
              )}
            </ContentFrame>

            <ContentFrame>
              <LabelFrame sx={{ width: '32.8%' }}>
                <Typography variant='subtitle2'>Заключение поверки</Typography>
                <RadioButton
                  value={totalAccepted}
                  onChange={changeTotalAccepted}
                  trueLabel={'Пригоден'}
                  falseLabel={'Непригоден'}
                  trueValue={true}
                  falseValue={false}
                />
              </LabelFrame>

              <LabelFrame sx={{ width: '32.8%' }}>
                <Typography variant='subtitle2'>Номер свидетельства или извещения</Typography>
                <Input
                  placeholder={'Пример ввода - АИК/24-08-2024/382808433'}
                  type={'text'}
                  value={arshinId}
                  error={!!errors.certificate}
                  actions={{
                    change: changeArshinId,
                  }}
                />
                {errors.certificate && <div className='error'>{errors.certificate[0]}</div>}
              </LabelFrame>
            </ContentFrame>
          </Content>

          <Button
            onClick={handleSave}
            label='Добавить запись'
            disabled={isDisabledBtn}
            color='primary'
            fullWidth
          />
        </Wrapper>
      )}
    </main>
  )
}

export default Add
