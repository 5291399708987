// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import useUpdate from '../hooks/useDataUpdater'
import { useSelector } from 'react-redux'

const Updater = () => {
  const [
    updateCompanies,
    updateCases,
    updateMasterArhin,
    updateVerifications,
    updateTickets,
    updateKitVerifications,
    updateKitPassport,
    updateConditions,
    updateIssueCase,
    updateComplect,
    updateReceived,
    updateArchive,
    updateEntriesArchive,
    updateEtalon,
    updateMeters,
    updateUsers,
    updateProtocols,
    updateHeaderData,
  ] = useUpdate()

  const userRole = useSelector(state => state.headerReducer.role)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const token = useSelector(state => state.authReducer.authData.token)
  const measurement_id = useSelector(state => state.meterReducer.measurement_id)

  const search_query = useSelector(state => state.meterReducer.searchQuery)
  const search_query_users = useSelector(state => state.usersReducer.searchQuery)
  const search_query_etalon = useSelector(state => state.etalonReducer.searchQuery)
  const search_query_cases = useSelector(state => state.casesReducer.searchQuery)
  const search_query_companies = useSelector(state => state.companiesReducer.searchQuery)

  const companiesPing = useSelector(state => state.updaterReducer.companiesPing)
  const casesPing = useSelector(state => state.updaterReducer.casesPing)
  const masterArshinPing = useSelector(state => state.updaterReducer.masterArshinPing)
  const ticketsPing = useSelector(state => state.updaterReducer.ticketsPing)
  const kitVerificationsPing = useSelector(state => state.updaterReducer.kitVerificationsPing)
  const kitPassportPing = useSelector(state => state.updaterReducer.kitPassportPing)
  const conditionsPing = useSelector(state => state.updaterReducer.conditionsPing)
  const issueCasePing = useSelector(state => state.updaterReducer.issueCasePing)
  const complectsPing = useSelector(state => state.updaterReducer.complectsPing)
  const receivedsPing = useSelector(state => state.updaterReducer.receivedsPing)
  const archivesPing = useSelector(state => state.updaterReducer.archivesPing)
  const entriesArchivePing = useSelector(state => state.updaterReducer.entriesArchivePing)
  const etalonsPing = useSelector(state => state.updaterReducer.etalonsPing)
  const metersPing = useSelector(state => state.updaterReducer.metersPing)
  const usersPing = useSelector(state => state.updaterReducer.usersPing)
  const protocolsPing = useSelector(state => state.updaterReducer.protocolsPing)
  const headerPing = useSelector(state => state.updaterReducer.headerPing)

  const protocolSearchQuery = useSelector(state => state.protocolReducer.searchQuery)

  const measurementIdFilter = useSelector(state => state.protocolReducer.measurementIdFilter)
  const userId = useSelector(state => state.protocolReducer.userId)
  const statusId = useSelector(state => state.protocolReducer.statusId)

  const approvedTransfer = useSelector(state => state.protocolReducer.approvedTransfer)
  const approvedArshin = useSelector(state => state.protocolReducer.approvedArshin)
  const approvedFsa = useSelector(state => state.protocolReducer.approvedFsa)

  const dateFrom = useSelector(state => state.protocolReducer.dateFrom)
  const dateTo = useSelector(state => state.protocolReducer.dateTo)

  const journalId = useSelector(state => state.journalReducer.journalId)
  const entryId = useSelector(state => state.journalReducer.entryId)
  const searchQueryJournal = useSelector(state => state.journalReducer.searchQuery)
  const completeJournal = useSelector(state => state.journalReducer.complete)
  const dateFromJournal = useSelector(state => state.journalReducer.dateFrom)
  const dateUntilJournal = useSelector(state => state.journalReducer.dateUntil)
  const createDateFromJournal = useSelector(state => state.journalReducer.createDateFrom)
  const createDateUntilJournal = useSelector(state => state.journalReducer.createDateUntil)
  const creatorJournal = useSelector(state => state.journalReducer.creator)
  const totalAcceptedJournal = useSelector(state => state.journalReducer.totalAccepted)
  const typeMethodJournal = useSelector(state => state.journalReducer.typeMethod)
  const journalIdFilter = useSelector(state => state.journalReducer.journalIdFilter)
  const archiveFromDate = useSelector(state => state.journalReducer.archiveFilter.archiveFromDate)
  const archiveToDate = useSelector(state => state.journalReducer.archiveFilter.archiveToDate)
  const complete = useSelector(state => state.journalReducer.archiveFilter.complete)
  const masterArshine = useSelector(state => state.journalReducer.masterArshine)

  const measurementIdTickets = useSelector(state => state.journalReducer.tickets.measurementId)
  const arshinNameTickets = useSelector(state => state.journalReducer.tickets.arshinName)
  const isCheckedTickets = useSelector(state => state.journalReducer.tickets.isChecked)
  const completenessStatusTickets = useSelector(
    state => state.journalReducer.tickets.completenessStatus
  )
  const createdByRoleTickets = useSelector(state => state.journalReducer.tickets.createdByRole)
  const createdAtFromTickets = useSelector(state => state.journalReducer.tickets.createdAtFrom)
  const createdAtToTickets = useSelector(state => state.journalReducer.tickets.createdAtTo)
  const verificationDateFrom = useSelector(
    state => state.journalReducer.tickets.verificationDateFrom
  )
  const verificationDateTo = useSelector(state => state.journalReducer.tickets.verificationDateTo)

  // ----------------------------------------------------------------
  // журнал выдачи сп
  // ----------------------------------------------------------------

  const measurementId = useSelector(state => state.journalReducer.issueCaseFilter.measurementId)
  const innerName = useSelector(state => state.journalReducer.issueCaseFilter.innerName)
  const issuedDateFrom = useSelector(state => state.journalReducer.issueCaseFilter.issuedDateFrom)
  const issuedDateTo = useSelector(state => state.journalReducer.issueCaseFilter.issuedDateTo)
  const receivedByName = useSelector(state => state.journalReducer.issueCaseFilter.receivedByName)
  const isReturned = useSelector(state => state.journalReducer.issueCaseFilter.isReturned)
  const completenessStatus = useSelector(
    state => state.journalReducer.issueCaseFilter.completenessStatus
  )
  const createdByRole = useSelector(state => state.journalReducer.issueCaseFilter.createdByRole)
  const verificationDateFromIssue = useSelector(
    state => state.journalReducer.issueCaseFilter.verificationDateFrom
  )
  const verificationDateToIssue = useSelector(
    state => state.journalReducer.issueCaseFilter.verificationDateTo
  )

  // ----------------------------------------------------------------
  // журналы kit-verifications
  // ----------------------------------------------------------------
  const isReturnedKitVerifications = useSelector(
    state => state.journalReducer.kitVerifications.isReturned
  )
  const completenessStatusKitVerifications = useSelector(
    state => state.journalReducer.kitVerifications.completenessStatus
  )
  const createdByRoleKitVerifications = useSelector(
    state => state.journalReducer.kitVerifications.createdByRole
  )
  const verificationDateFromKitVerifications = useSelector(
    state => state.journalReducer.kitVerifications.verificationDateFrom
  )
  const verificationDateToKitVerifications = useSelector(
    state => state.journalReducer.kitVerifications.verificationDateTo
  )
  const createdAtFrom = useSelector(state => state.journalReducer.kitVerifications.createdAtFrom)
  const createdAtTo = useSelector(state => state.journalReducer.kitVerifications.createdAtTo)
  const expirationDateFrom = useSelector(
    state => state.journalReducer.kitVerifications.expirationDateFrom
  )
  const expirationDateTo = useSelector(
    state => state.journalReducer.kitVerifications.expirationDateTo
  )

  // ----------------------------------------------------------------
  // журналы kit-passports
  // ----------------------------------------------------------------
  const systemExistingStatus = useSelector(
    state => state.journalReducer.kitPassportsFilter.systemExistingStatus
  )
  const responsiblePassportName = useSelector(
    state => state.journalReducer.kitPassportsFilter.responsiblePassportName
  )
  const formedDateFrom = useSelector(
    state => state.journalReducer.kitPassportsFilter.formedDateFrom
  )
  const formedDateTo = useSelector(state => state.journalReducer.kitPassportsFilter.formedDateTo)

  // ----------------------------------------------------------------
  //
  // ----------------------------------------------------------------

  useEffect(() => {
    token && updateCompanies(search_query_companies)
  }, [companiesPing, search_query_companies])
  useEffect(() => {
    token && updateCases(search_query_cases, companyId)
  }, [companyId, casesPing, search_query_cases])
  useEffect(() => {
    token && updateMasterArhin(companyId)
  }, [companyId, masterArshinPing])
  useEffect(() => {
    token && updateEtalon(search_query_etalon, companyId)
  }, [companyId, etalonsPing, search_query_etalon])
  useEffect(() => {
    token && updateMeters(measurement_id, search_query)
  }, [metersPing, measurement_id, search_query])
  useEffect(() => {
    token && updateUsers(search_query_users, companyId)
  }, [usersPing, companyId, search_query_users])
  useEffect(() => {
    token &&
      updateProtocols(
        companyId,
        protocolSearchQuery,
        measurementIdFilter,
        userId,
        statusId,
        approvedTransfer,
        approvedArshin,
        approvedFsa,
        dateFrom,
        dateTo
      )
  }, [
    protocolsPing,
    companyId,
    protocolSearchQuery,
    measurementIdFilter,
    userId,
    statusId,
    approvedTransfer,
    approvedArshin,
    approvedFsa,
    dateFrom,
    dateTo,
  ])

  useEffect(() => {
    token && updateHeaderData()
  }, [headerPing])
  useEffect(() => {
    token && false && updateComplect(companyId, dateFromJournal, dateUntilJournal)
  }, [companyId, complectsPing, dateFromJournal, dateUntilJournal])
  useEffect(() => {
    token &&
      companyId &&
      updateTickets(
        companyId,
        searchQueryJournal,
        measurementIdTickets,
        arshinNameTickets,
        isCheckedTickets,
        completenessStatusTickets,
        createdByRoleTickets,
        createdAtFromTickets,
        createdAtToTickets,
        verificationDateFrom,
        verificationDateTo
      )
  }, [
    companyId,
    searchQueryJournal,
    measurementIdTickets,
    arshinNameTickets,
    isCheckedTickets,
    completenessStatusTickets,
    createdByRoleTickets,
    createdAtFromTickets,
    createdAtToTickets,
    verificationDateFrom,
    verificationDateTo,
    ticketsPing,
  ])
  useEffect(() => {
    token &&
      companyId &&
      updateKitVerifications(
        companyId,
        searchQueryJournal,
        isReturnedKitVerifications,
        completenessStatusKitVerifications,
        createdByRoleKitVerifications,
        verificationDateFromKitVerifications,
        verificationDateToKitVerifications,
        createdAtFrom,
        createdAtTo,
        expirationDateFrom,
        expirationDateTo
      )
  }, [
    companyId,
    searchQueryJournal,
    isReturnedKitVerifications,
    completenessStatusKitVerifications,
    createdByRoleKitVerifications,
    verificationDateFromKitVerifications,
    verificationDateToKitVerifications,
    createdAtFrom,
    createdAtTo,
    expirationDateFrom,
    expirationDateTo,
    kitVerificationsPing,
  ])
  useEffect(() => {
    token &&
      companyId &&
      updateKitPassport(
        companyId,
        searchQueryJournal,
        systemExistingStatus,
        responsiblePassportName,
        formedDateFrom,
        formedDateTo
      )
  }, [
    companyId,
    searchQueryJournal,
    systemExistingStatus,
    responsiblePassportName,
    formedDateFrom,
    formedDateTo,
    kitPassportPing,
  ])
  useEffect(() => {
    token &&
      companyId &&
      updateConditions(
        companyId,
        searchQueryJournal,
        completeJournal,
        dateFromJournal,
        dateUntilJournal
      )
  }, [
    companyId,
    searchQueryJournal,
    completeJournal,
    dateFromJournal,
    dateUntilJournal,
    conditionsPing,
  ])
  useEffect(() => {
    token &&
      companyId &&
      updateIssueCase(
        companyId,
        searchQueryJournal,
        measurementId,
        innerName,
        issuedDateFrom,
        issuedDateTo,
        receivedByName,
        isReturned,
        completenessStatus,
        createdByRole,
        verificationDateFromIssue,
        verificationDateToIssue
      )
  }, [
    companyId,
    searchQueryJournal,
    measurementId,
    innerName,
    issuedDateFrom,
    issuedDateTo,
    receivedByName,
    isReturned,
    completenessStatus,
    createdByRole,
    verificationDateFromIssue,
    verificationDateToIssue,
    issueCasePing,
  ])
  useEffect(() => {
    token &&
      companyId &&
      updateReceived(
        companyId,
        searchQueryJournal,
        measurementIdFilter,
        masterArshine,
        totalAcceptedJournal,
        typeMethodJournal,
        completeJournal,
        creatorJournal,
        dateFromJournal,
        dateUntilJournal,
        createDateFromJournal,
        createDateUntilJournal
      )
  }, [
    companyId,
    searchQueryJournal,
    measurementIdFilter,
    masterArshine,
    totalAcceptedJournal,
    typeMethodJournal,
    completeJournal,
    creatorJournal,
    dateFromJournal,
    dateUntilJournal,
    createDateFromJournal,
    createDateUntilJournal,
    receivedsPing,
  ])
  useEffect(() => {
    token &&
      companyId &&
      updateArchive(
        companyId,
        searchQueryJournal,
        archiveFromDate,
        archiveToDate,
        journalIdFilter,
        '',
        complete
      )
  }, [
    companyId,
    archivesPing,
    searchQueryJournal,
    archiveFromDate,
    archiveToDate,
    journalIdFilter,
    complete,
  ])
  useEffect(() => {
    token &&
      companyId &&
      entryId &&
      updateEntriesArchive(
        companyId,
        journalId,
        entryId,

        searchQueryJournal,

        completeJournal,
        dateFromJournal,
        dateUntilJournal,

        measurementIdFilter,
        masterArshine,
        totalAcceptedJournal,
        typeMethodJournal,
        creatorJournal,
        createDateFromJournal,
        createDateUntilJournal,

        measurementIdTickets,
        arshinNameTickets,
        isCheckedTickets,
        completenessStatusTickets,
        createdByRoleTickets,
        createdAtFromTickets,
        createdAtToTickets,
        verificationDateFrom,
        verificationDateTo,

        measurementId,
        innerName,
        issuedDateFrom,
        issuedDateTo,
        receivedByName,
        isReturned,
        completenessStatus,
        createdByRole,

        createdAtFrom,
        createdAtTo,
        verificationDateFromKitVerifications,
        verificationDateToKitVerifications,
        expirationDateFrom,
        expirationDateTo,
        isReturnedKitVerifications,
        createdByRoleKitVerifications,

        formedDateFrom,
        formedDateTo,
        systemExistingStatus,
        responsiblePassportName
      )
  }, [
    companyId,
    journalId,
    entryId,

    searchQueryJournal,

    completeJournal,
    dateFromJournal,
    dateUntilJournal,

    measurementIdFilter,
    masterArshine,
    totalAcceptedJournal,
    typeMethodJournal,
    creatorJournal,
    createDateFromJournal,
    createDateUntilJournal,

    measurementIdTickets,
    arshinNameTickets,
    isCheckedTickets,
    completenessStatusTickets,
    createdByRoleTickets,
    createdAtFromTickets,
    createdAtToTickets,
    verificationDateFrom,
    verificationDateTo,

    measurementId,
    innerName,
    issuedDateFrom,
    issuedDateTo,
    receivedByName,
    isReturned,
    completenessStatus,
    createdByRole,

    createdAtFrom,
    createdAtTo,
    verificationDateFromKitVerifications,
    verificationDateToKitVerifications,
    expirationDateFrom,
    expirationDateTo,
    isReturnedKitVerifications,
    createdByRoleKitVerifications,

    formedDateFrom,
    formedDateTo,
    systemExistingStatus,
    responsiblePassportName,
  ])

  useEffect(() => {
    false && console.log('updater is ready')
  }, [])
  return <React.Fragment></React.Fragment>
}

export default Updater
