import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setOpen, resetState } from '../../../../store/slices/app/comps/modal'
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material'
import Button from '../../../../ui/button/index'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const ModalComponent = () => {
  const dispatch = useDispatch()

  const open = useSelector(state => state.modalReducer.open)
  const title = useSelector(state => state.modalReducer.title)
  const children = useSelector(state => state.modalReducer.children)
  const accept = useSelector(state => state.modalReducer.buttonAccept)
  const dismiss = useSelector(state => state.modalReducer.buttonDismiss)
  const showAccept = useSelector(state => state.modalReducer.showButtonAccept)
  const showDismiss = useSelector(state => state.modalReducer.showButtonDismiss)

  const confirmAction = useSelector(state => state.modalReducer.confirmAction)

  // ----------------------------------------------------------------
  // персонализированные параметры - перервоначально привязка
  // происходит к title ( под вопросом, возможно лучше переделать)
  // ----------------------------------------------------------------

  const deletedDate = useSelector(state => state.journalReducer.archived.deletedDate)

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => dispatch(setOpen(false))}
        aria-describedby='alert-dialog-slide-description'
        sx={{
          '& .MuiDialog-container': {
            backdropFilter: 'blur(2px)',
          },
          '& .MuiDialog-paper': {
            borderRadius: '20px',
            boxShadow: 24,
            padding: '16px',
            width: '700px',
          },
          '& .MuiDialogActions-root': {
            padding: '10px 24px 20px',
            gap: '14px',
          },
        }}
      >
        {title && (
          <DialogTitle
            style={{
              textTransform: 'none',
              fontSize: '20px',
            }}
          >
            {title}
          </DialogTitle>
        )}
        <DialogContent
          sx={{
            lineHeight: '1.5',
          }}
        >
          {children}
        </DialogContent>
        <DialogActions>
          {showDismiss && (
            <Button
              onClick={() => {
                dispatch(setOpen(false))
                dispatch(resetState())
              }}
              label={dismiss ?? 'Отменить'}
              color='secondary'
            />
          )}
          {showAccept && (
            <Button
              onClick={() => {
                if (confirmAction) {
                  switch (title.toLowerCase()) {
                    case 'уничтожение документа':
                      confirmAction(deletedDate)
                      break
                    default:
                      confirmAction()
                      break
                  }
                }
              }}
              label={accept ?? 'Принять'}
            />
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default ModalComponent
