// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable @typescript-eslint/no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import Tooltip from '../../../ui/tooltip'
import Button from '../../../ui/button'
import FilterIcon from '../../../img/icon/FilterIcon'
import PencilIcon from '../../../img/icon/PencilIcon'
import DeleteIcon from '../../../img/icon/DeleteIcon'
import { Typography, Fade, Collapse } from '@mui/material'

import R from '../../../services/app/request.service'
import Table from '../../components/LocalElements/Table/Table'
import FilterCompany from '../../components/LocalElements/Filter/FilterCompany'
import SearchQuery from '../../components/LocalElements/Search/SearchQuery'
import Skeleton from '../../components/LocalElements/Skeleton/Skeleton'
import BasicTabs from '../../components/LocalElements/TabsMenu/TabsMenu'

import useWindowDimensions from '../../hooks/useWindowDimensions'

import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
} from '../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../store/slices/app/comps/snackbar'
import {
  setDataEtalon,
  setSearchQuery as setSearchQueryEtalon,
} from '../../../store/slices/app/views/etalon'
import { refreshEtalons } from '../../../store/slices/app/controlers/updater'
import { setName } from '../../../store/slices/app/controlers/pageName'

import { IModelEtalonsList } from '../../types'

const IndexEtalons = () => {
  const userRole = useSelector((state: any) => state.headerReducer.role)

  const { width } = useWindowDimensions()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isToggle = useSelector((state: any) => state.toggleReducer.isToggle)
  const isLoading = useSelector((state: any) => state.etalonReducer.isLoading)
  const dataEtalons = useSelector((state: any) => state.etalonReducer.dataEtalon)

  const etalonSearchQuery = useSelector((state: any) => state.etalonReducer.searchQuery)
  const companyIdSuperAdmin = useSelector((state: any) => state.companyIdReducer.companyId)
  const companyIdUser = useSelector((state: any) => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const changeSearchQuery = (param: any) => {
    dispatch(setSearchQueryEtalon(param))
  }

  const handleCreate = () => {
    navigate('/metriva/etalon/create')
  }

  const handleEdit = (param: { id: any }) => {
    navigate(`/metriva/etalon/${param.id}/edit`)
  }

  const handleDelete = async (etalonId: any) => {
    const { status } = await R.deleteEtalon(etalonId)
    if (status === 200) {
      dispatch(refreshEtalons(''))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Средство поверки было удалено'))
      dispatch(setOpen(false))
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Произошла ошибка обращения к API'))
    }
  }

  let colData = [
    {
      field: 'title',
      headerName: 'Наименование и тип',
      type: 'string',
      disableFilter: true,
    },
    {
      field: 'manufacturerNumber',
      headerName: 'Заводской номер',
      type: 'string',
      disableFilter: true,
    },
    {
      field: 'inventoryNumber',
      headerName: 'Инвентарный номер',
      type: 'string',
      disableFilter: true,
    },
    {
      field: 'status',
      headerName: 'Статус',
      type: 'string',
      renderCell: (params: {
        row: {
          status:
            | 'in_storage'
            | 'in_use'
            | 'verification'
            | 'attestation'
            | 'calibration'
            | 'repair'
            | 'decommissioned'
        }
      }) => {
        const { status } = params.row

        if (status === 'in_use') {
          return <p style={{ color: '#00A37C', fontSize: '0.875rem' }}>В эксплуатации</p>
        } else if (status === 'in_storage') {
          return <p style={{ color: 'gray', fontSize: '0.875rem' }}>Хранение (консервация)</p>
        } else if (status === 'verification') {
          return <p style={{ color: '#E57004', fontSize: '0.875rem' }}>Поверка</p>
        } else if (status === 'attestation') {
          return <p style={{ color: '#E57004', fontSize: '0.875rem' }}>Аттестация</p>
        } else if (status === 'calibration') {
          return <p style={{ color: '#E57004', fontSize: '0.875rem' }}>Калибровка</p>
        } else if (status === 'repair') {
          return <p style={{ color: '#E57004', fontSize: '0.875rem' }}>Ремонт</p>
        } else if (status === 'decommissioned') {
          return <p style={{ color: '#E74953', fontSize: '0.875rem' }}>Списано</p>
        }
      },
    },
    {
      field: 'group',
      headerName: 'Группа СП',
      type: 'string',
    },
    {
      field: 'isSyncAvailable',
      headerName: 'Отправка данных о СП в Аршин',
      type: 'boolean',
    },
    {
      field: 'listedInSets',
      headerName: 'СП числится в комплектах',
      type: 'boolean',
      // renderCell: (params: { row: { listedInSets: { inner_name: string }[] } }) => {
      //   return params.row.listedInSets && params.row.listedInSets.length > 0
      //     ? params.row.listedInSets.map(itemCase => itemCase.inner_name).join(', ')
      //     : '-'
      // },
      // filterable: true,
      // valueGetter: (params: { row: { listedInSets: { inner_name: string }[] } }) => {
      //   return params.row.listedInSets && params.row.listedInSets.length > 0
      // },
    },
    {
      field: 'lastCheckDate',
      headerName: 'Дата последней поверки/аттестации',
      type: 'string',
      disableFilter: true,
    },
    {
      field: 'endCheckDate',
      headerName: 'Дата истечения срокаповерки/аттестации',
      type: 'string',
      disableFilter: true,
    },

    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      getActions: (item: any) => {
        console.log(item)

        return [
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <Tooltip title='Редактировать средство поверки' disableHoverListener={undefined}>
              <div style={{ cursor: 'pointer' }} onClick={() => handleEdit(item)}>
                <PencilIcon onClick={undefined} />
              </div>
            </Tooltip>
            {item.row.listedInSets === false && (
              <Tooltip title='Удалить средство поверки' disableHoverListener={undefined}>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(setOpen(true))
                    dispatch(setTitle('Вы действительно хотите удалить СП?'))
                    dispatch(setChildren('Отменить действие будет уже невозможно.'))
                    dispatch(setConfirmAction(() => handleDelete(item.id)))
                  }}
                >
                  <DeleteIcon onClick={() => {}} />
                </div>
              </Tooltip>
            )}
            {item.row.listedInSets === true && (
              <Tooltip
                title='Удаление недоступно, средство поверки является частью комплекта СП'
                disableHoverListener={undefined}
              >
                <div style={{ cursor: 'pointer', opacity: 0.8 }} onClick={() => {}}>
                  <DeleteIcon onClick={() => {}} />
                </div>
              </Tooltip>
            )}
          </div>,
        ]
      },
    },
  ]

  useEffect(() => {
    dispatch(setName('База эталонов'))
  }, [])

  return (
    <main className={isToggle ? 'main_open' : 'main'}>
      {userRole === 'super' && (
        <div
          style={{
            width: width > 1050 ? '605px' : '100%',
            marginBottom: '0px',
          }}
        >
          <FilterCompany />
        </div>
      )}

      <Collapse in={!companyId} timeout={500} unmountOnExit>
        <div className='page_null'>
          <Fade in={!companyId} timeout={500}>
            <Typography variant='body1'>Для просмотра данных выберите компанию</Typography>
          </Fade>
        </div>
      </Collapse>

      <Collapse in={!!companyId} timeout={500} unmountOnExit>
        <Fade in={!!companyId} timeout={500}>
          <div>
            <div style={{ margin: '10px 0 12px' }}>
              <BasicTabs
                viewVariant={0}
                callback={() => {}}
                tabs={[{ label: 'Список средств поверки' }]}
                containnerCSS={undefined}
              />
            </div>

            {isLoading ? (
              <Skeleton />
            ) : (
              <Table
                colData={colData}
                rowData={dataEtalons.map((item: IModelEtalonsList) => ({
                  id: item.id,
                  title: item.name_type,
                  manufacturerNumber: item.manufacturer_number ?? '-',
                  inventoryNumber: item.inventory_number ?? '-',
                  isSyncAvailable: item.is_sync_available,
                  group: item.kit_group_name,
                  status: item.status,
                  lastCheckDate: moment(item.last_date).format('DD-MM-YYYY'),
                  endCheckDate: moment(item.expired_date).format('DD-MM-YYYY'),
                  listedInSets: (item.cases && item.cases.length > 0) === true,
                }))}
                rowCallback={handleEdit}
                addButtonCallback={handleCreate}
                searchParams={{ query: etalonSearchQuery, builder: changeSearchQuery }}
                showToolbar={true}
                hasCheckboxSelection={true}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
              />
            )}
          </div>
        </Fade>
      </Collapse>
    </main>
  )
}

export default IndexEtalons
